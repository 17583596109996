<template>
  <div>
    <BaseThemeComponent
      v-if="
        thePage.contents.ns_1_title ||
          thePage.contents.ns_1_description ||
          thePage.contents.ns_1_img
      "
      name="PageSectionNarrative"
      section-id="1"
      class="bloak-blue--text pt-5"
      :bg-color="thePage.contents.ns_1_bg_color"
      :title-class="thePage.contents.ns_1_title_class"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_1_description"
            tag="p"
            class="r-headline"
          />
          <component
            :is="
              thePage.contents.ns_1_btn_outline_color
                ? 'BaseButtonOutline'
                : 'BaseButton'
            "
            v-if="thePage.contents.ns_1_btn_text"
            :outline-color="thePage.contents.ns_1_btn_outline_color"
            :color="thePage.contents.ns_1_btn_color"
            class="ml-0"
            :to="thePage.contents.ns_1_btn_route"
            :href="thePage.contents.ns_1_btn_link"
          >
            {{ thePage.contents.ns_1_btn_text }}
          </component>
          <component
            :is="
              thePage.contents.ns_1_btn2_outline_color
                ? 'BaseButtonOutline'
                : 'BaseButton'
            "
            v-if="thePage.contents.ns_1_btn2_text"
            :outline-color="thePage.contents.ns_1_btn2_outline_color"
            :color="thePage.contents.ns_1_btn2_color"
            class="ml-0"
            :to="thePage.contents.ns_1_btn2_route"
            :href="thePage.contents.ns_1_btn2_link"
          >
            {{ thePage.contents.ns_1_btn2_text }}
          </component>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_2_title ||
          thePage.contents.ns_2_description ||
          thePage.contents.ns_2_img ||
          thePage.contents.ns_2_bg_img
      "
      id="3"
      name="PageSection"
      :bg-image="thePage.contents.ns_2_bg_img"
    >
      <VLayout row justify-center text-xs-center text-sm-left>
        <VFlex text-xs-center pa-4 xs12 sm9>
          <BasePageContent
            content-key="ns_2_title"
            tag="h1"
            tag-class="white--text header-4"
          />
          <BaseButton
            v-if="thePage.contents.ns_2_btn_text"
            :outline-color="thePage.contents.ns_2_btn_outline_color"
            :color="thePage.contents.ns_2_btn_color"
            :to="thePage.contents.ns_2_btn_route"
            :href="thePage.contents.ns_2_btn_link"
            :target="thePage.contents.ns_2_btn_target || null"
            large
          >
            {{ thePage.contents.ns_2_btn_text }}
          </BaseButton>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.call_now_title"
      name="PageSection"
      :title="thePage.contents.call_now_title"
      section-name="call_now"
    />
    <BaseThemeComponent
      v-if="
        thePage.contents.ns_3_title ||
          thePage.contents.ns_3_description ||
          thePage.contents.ns_3_img ||
          thePage.contents.ns_3_bg_img
      "
      name="PageSectionNarrative"
      section-id="3"
      class="bloak-blue--text"
      title-class="header-2"
      :text-cols="8"
      content-bg-color="white"
      :bg-image="thePage.contents.ns_3_bg_img"
      full-bleed-bg
    >
      <VLayout row>
        <VFlex xs12>
          <BasePageContent
            content-key="ns_3_description"
            tag="p"
            class="r-headline"
          />
          <BaseButton
            v-if="thePage.contents.ns_3_btn_text"
            :outline-color="thePage.contents.ns_3_btn_outline_color"
            :color="thePage.contents.ns_3_btn_color"
            class="ml-0"
            :to="thePage.contents.ns_3_btn_route"
            :href="thePage.contents.ns_3_btn_link"
          >
            {{ thePage.contents.ns_3_btn_text }}
          </BaseButton>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_4_title ||
          thePage.contents.ns_4_description ||
          thePage.contents.ns_4_img
      "
      name="PageSectionNarrative"
      section-id="4"
      class="bloak-blue--text pt-5"
      :bg-color="thePage.contents.ns_4_bg_color"
      :title-class="thePage.contents.ns_4_title_class"
      :text-cols="6"
      full-bleed-bg
    >
      <VLayout row>
        <VFlex xs12 lg11>
          <BasePageContent
            content-key="ns_4_description"
            tag="p"
            class="r-headline"
          />
          <BaseButton
            v-if="thePage.contents.ns_4_btn_text"
            :outline-color="thePage.contents.ns_4_btn_outline_color"
            :color="thePage.contents.ns_4_btn_color"
            class="ml-0"
            :to="thePage.contents.ns_4_btn_route"
            :href="thePage.contents.ns_4_btn_link"
          >
            {{ thePage.contents.ns_4_btn_text }}
          </BaseButton>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_5_title ||
          thePage.contents.ns_5_description ||
          thePage.contents.ns_5_img
      "
      name="PageSectionNarrative"
      section-id="5"
      class="white--text"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_5_description"
            tag="p"
            class="r-headline"
          />
          <BaseButtonOutline
            v-if="thePage.contents.ns_5_btn_text"
            :outline-color="thePage.contents.ns_5_btn_outline_color"
            class="ml-0"
            :to="thePage.contents.ns_5_btn_route"
            :href="thePage.contents.ns_5_btn_link"
          >
            {{ thePage.contents.ns_5_btn_text }}
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>
  </div>
</template>

<script>
import formatPhone from '@utils/format-phone'
import PageMixin from '@mixins/page'

export default {
  name: 'BlueOakHSVCatering',
  components: {},
  mixins: [PageMixin],
  data() {
    return {}
  },
  methods: { formatPhone },
}
</script>
